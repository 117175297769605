import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import Drum from 'core/components/Drum';
import withBindProps from 'core/hocs/withBindProps';

import SectionTitle from 'site/components/SectionTitle';
import SkewedRaker from 'site/components/SkewedRaker';
import { Card5Type1 } from 'site/cards/Card5';

import styles from './index.styl';


const HeaderComponent = withBindProps({ level: 1, align: 'center', className: styles.sectionTitle })(SectionTitle);
const VideoCard = withBindProps({ imageMaxWidth: 252, imageMaxHeight: 170 })(Card5Type1);

const VideoDrumRaker = ({ content, isDesktop }) =>
  isDesktop ? (
    <div data-qa='video'>
      <SkewedRaker
        content={content}
        color='#000'
        sidebarColumnCount={2}
        dataQa='video-raker'
      />
    </div>
  ) : (
    <Drum
      content={content}
      title='Видео'
      headerComponent={HeaderComponent}
      card={VideoCard}
      spaceBetween={15}
      itemWidthMobile={252 + 15}
      dataQa='video-drum'
    />
  );

VideoDrumRaker.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(VideoDrumRaker);
