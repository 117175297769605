import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import Feed from 'core/components/Feed';

import { Card4Type2 } from 'site/cards/Card4';
import { Card3Type5 } from 'site/cards/Card3';

/**
 * Компонент предназначен для отображения топиков листа `column_3` на главной.
 */
function Column2ListTopics({ content, isDesktop }) {
  return (
    <Feed
      content={content}
      card={isDesktop ? Card4Type2 : Card3Type5}
      dataQa='column2-list'
    />
  );
}

Column2ListTopics.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Column2ListTopics);
