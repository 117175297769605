import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';
import withBindProps from 'core/hocs/withBindProps';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import Divider from 'core/components/Divider';

import resolveRelationships from 'core/utils/relationships';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card3(props) {
  const {
    type,
    content,
    theme,
    utm,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    imageSize,
    imageBorder,
    topDivider,
    bottomDivider,
    showAltHeadline,
  } = mapTypes[type] || {};

  const {
    image: {
      versions: {
        original: cover,
      },
    },
  } = relationships(content);

  const {
    remote_image: remoteImage,
  } = content.extra || {};
  const to = utm ? link + utm : link;
  return (
    <div className={cx('card3', styles.card)} data-qa='card3'>

      <style jsx>{`
        .${styles.imageBorder}
          border 3px solid ${theme.colors.layout}
      `}</style>

      {topDivider && <Divider className={styles.topDivider} />}

      <Link
        to={to}
        type='secondary'
        className={styles.link}
        data-qa='card3-link'
      >
        <div className={cx(styles.image, imageBorder && styles.imageBorder)}>
          <Image
            width={imageSize}
            height={imageSize}
            src={cover}
            position='50% 0'
            {...remoteImage && { url: remoteImage }}
          />
        </div>
        <div className={styles['card-content']} data-qa='card3-content'>
          <div
            className={cx(
              styles['card-content_block'],
              styles['card-headline'],
            )}
            data-qa='card3-headline'
          >
            {listHeadline || headline}
          </div>
          {showAltHeadline && altHeadline && (
            <div className={styles.altHeadline}>
              {altHeadline}
            </div>
          )}
        </div>
      </Link>

      {bottomDivider && <Divider className={styles.bottomDivider} />}
    </div>
  );
}

Card3.propTypes = {
  type: PropTypes.oneOf(Object.keys(mapTypes)),
  content: PropTypes.object,
  theme: PropTypes.object,
  utm: PropTypes.string,
};

Card3.defaultProps = {
  type: 0,
};

const Card = withCard(Card3);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const Card3Type1 = withBindProps({ type: 1 })(Card);
export const Card3Type2 = withBindProps({ type: 2 })(Card);
export const Card3Type3 = withBindProps({ type: 3 })(Card);
export const Card3Type4 = withBindProps({ type: 4 })(Card);
export const Card3Type5 = withBindProps({ type: 5 })(Card);

export default Card;
