import PropTypes from 'prop-types';
import { getContext } from 'core/libs/recompose';
import withBreakpoint from 'core/hocs/withBreakpoint';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';


function Logo({ amp, breakpoint, themeName, host, width, height, isDesktop, isHeader }) {
  const Tag = amp ? 'amp-img' : 'img';
  const altLogo = host.replace('www.', '');

  return (
    <Tag
      src={`/icons/logo_${breakpoint}_${themeName}.svg`}
      alt={altLogo}
      width={width}
      height={height}
      style={isHeader && isDesktop ? { paddingBottom: 10 } : {}}
    />
  );
}

Logo.propTypes = {
  amp: PropTypes.bool,
  host: PropTypes.string.isRequired,
  themeName: PropTypes.oneOf(['light', 'dark']).isRequired,
  breakpoint: breakpointPropTypes(),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isHeader: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

Logo.defaultProps = {
  themeName: 'light',
};

export default getContext({
  amp: PropTypes.bool,
  host: PropTypes.string,
})(withBreakpoint(Logo));
