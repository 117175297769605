import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';
import pluralize from 'core/utils/pluralize';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { dateFormat } from 'core/utils/dates';
import { getFullYearsDelta } from 'site/utils';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';
import Image from 'core/components/Image';
import { Block, Position, Section } from 'core/components/Grid';
import Divider from 'core/components/Divider';
import Link from 'core/components/Link';
import Breadcrumbs from 'core/components/Breadcrumbs';
import { Desktop, Mobile } from 'core/components/breakpoint';

import ClipStarImage from 'site/components/Icon/ClipStarImage';
import TopicContent from 'site/components/TopicContent';
import TopicH1 from 'site/components/TopicH1';
import NumberEqualHeight from 'site/components/NumberEqualHeight';

import styles from './index.styl';

import { ContentVerticalIndent, VerticalIndent } from 'site/components/Indents';

const relationships = resolveRelationships(
  ['image', 'person_meta', 'content', 'rubric'],
  {},
  { image: { versions: {} } },
);

const STAR_WIDTH = 300;
const DATES_FORMAT = 'd MMMM y';


function StarTopic(props) {
  const {
    content,
    theme,
    rawRubric,
    isMobile,
  } = props;

  const {
    attributes: {
      headline,
      updated_at: updatedAt,
    },
  } = content;

  const {
    image: {
      versions: {
        star: cover,
        thumbnail: previewCover,
      },
      caption,
    },
    person_meta: {
      birth_date: birthDateString,
      death_date: deathDateString,
      birth_place: birthPlace,
      gender,
      height,
      weight,
      zodiac_ru: zodiac,
    },
  } = relationships(content);

  const altAndTitle = caption || headline;

  const birthDate = new Date(birthDateString);
  const lastDate = deathDateString
    ? new Date(deathDateString)
    : new Date();
  const starAge = getFullYearsDelta(birthDate, lastDate);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.imageMask}
          & :global([id*="$"])
            fill ${theme.colors.content}

        ${styles.horoscopeLink}
          color ${theme.colors.primary}
      `}</style>
    </scope>
  );

  /**
   * TODO: Структура описана по макету, а не данным.
   */
  const starData = [
    {
      number: starAge,
      numberDescr: pluralize(starAge, ['год', 'года', 'лет']),
      info: gender === 'm' ? 'Родился' : 'Родилась',
      infoDescr: dateFormat(birthDate, DATES_FORMAT),
    },
    {
      number: height,
      numberDescr: 'см',
      info: 'Место рождения',
      infoDescr: birthPlace,
    },
    {
      number: weight,
      numberDescr: 'кг',
      info: 'Знак зодиака',
      infoDescr: zodiac.charAt(0).toUpperCase() + zodiac.slice(1),
    },
  ];

  return (
    <div data-qa='topic'>
      <style jsx>{`
        .${styles.breadcrumbs}
          font ${theme.texts.caption1.font}

        .${styles.numberDescr}
          color ${theme.colors.primary}
          font ${isMobile ? '14px' : '21px'}/1.2 ${theme.fonts.nuance}

        .${styles.info}
          color ${theme.colors.primary}
          font ${isMobile ? '10px' : '12px'}/1.15 ${theme.fonts.nuance}

        .${styles.updatedDate}
          color ${theme.colors.primary}
          font 14px/1.2 ${theme.fonts.nuance}

        .${styles.infoDescr}
          color ${theme.colors.primary}
          font ${isMobile ? '18px' : '22px'}/26px ${theme.fonts.display}
      `}</style>

      <Section>
        <Block
          mobile={12}
          reorderMobile={1}
        >
          <Block mobile={0}>
            <div className={styles.breadcrumbs}>
              <Breadcrumbs rawRubric={rawRubric} />
              <span className={styles.updatedDate}>
                Обновлено {dateFormat(updatedAt, DATES_FORMAT)}
              </span>
            </div>
            <ContentVerticalIndent />
          </Block>
          <TopicH1 textAlign={isMobile ? 'center' : 'left'}>{headline}</TopicH1>
        </Block>
        <Block width={`${STAR_WIDTH}px`} mobile={12}>
          <Block mobile={12} width={0}>
            <div className={styles.breadcrumbs}>
              <Breadcrumbs rawRubric={rawRubric} />
              <span className={styles.updatedDate}>
                {dateFormat(updatedAt, DATES_FORMAT)}
              </span>
            </div>
            <ContentVerticalIndent />
          </Block>
          <Position center>
            <div className={styles.imgWrapper}>
              <Image
                src={cover}
                previewSrc={previewCover}
                position='50% 0'
                width={STAR_WIDTH}
                aspectRatio={1}
                alt={altAndTitle}
                title={altAndTitle}
              />
              <ClipStarImage
                width='100%'
                height='100%'
                className={scoped.wrapClassNames(styles.imageMask)}
              />
            </div>
          </Position>
        </Block>
      </Section>
      <VerticalIndent />
      <div className={styles.panel}>
        {starData.map((item, index) => {
          const infoDescr = <div className={styles.infoDescr}>{item.infoDescr}</div>;
          return (
            <div className={styles.panelBox} key={index}>
              <Desktop>
                <div className={cx(styles.panelItem, index === 2 && styles.weightPanel)}>
                  <div className={styles.panelNumber}>
                    <NumberEqualHeight
                      value={item.number}
                      capitalHeight={81}
                      weight={200}
                    />
                    <div className={styles.numberDescr}>{item.numberDescr}</div>
                  </div>
                </div>
                <Divider />
                <ContentVerticalIndent />
              </Desktop>
              <div className={cx(styles.panelItem, index === 2 && styles.weightPanel)}>
                <div className={styles.info}>{item.info}</div>
                {item.info === 'Знак зодиака' ?
                  <Link
                    className={scoped.wrapClassNames(styles.horoscopeLink)}
                    to='/horoscope'
                  >
                    {infoDescr}
                  </Link> :
                  infoDescr
                }
              </div>
            </div>
          );
        })}
        <Mobile>
          <Divider className={styles.divider} />
          <div className={styles.panelRow}>
            {starData.map((item, index) => {
              return (
                <div className={cx(styles.panelItem, index === 1 && styles.heightPanel, index === 2 && styles.weightPanel)} key={item.info}>
                  <div className={styles.panelNumber}>
                    <NumberEqualHeight
                      value={item.number}
                      capitalHeight={37}
                      lineHeight={1.9}
                      weight={200}
                    />
                    <div className={styles.numberDescr}>{item.numberDescr}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Mobile>
      </div>
      <ContentVerticalIndent />
      <Divider />
      <VerticalIndent />
      <TopicContent content={content} />
      <scoped.styles />
    </div>
  );
}

StarTopic.propTypes = {
  isMobile: PropTypes.bool,
  rawRubric: PropTypes.object,
  content: modelPropTypes(topicAttributes).isRequired,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(StarTopic));
