import { Fragment } from 'react';
import PropTypes from 'prop-types';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Link from 'core/components/Link';
import Bouesque from 'core/components/Bouesque';
import ThemeSwitch from 'core/components/ThemeSwitch';
import { Block, Section } from 'core/components/Grid';
import { Desktop, Mobile } from 'core/components/breakpoint';
import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import {
  SideIndent,
  VerticalIndent,
  NegativeMobileSideIndent,
  BLOCK_VERTICAL,
  BLOCK_VERTICAL_MOBILE,
} from 'site/components/Indents';

import Popular from 'site/components/Popular';
import SkewedContainer from 'site/components/SkewedContainer';
import MainListTopics from 'site/components/MainListTopics';
import Column1ListTopics from 'site/components/Column1ListTopics';
import Column2ListTopics from 'site/components/Column2ListTopics';
import SectionTitle from 'site/components/SectionTitle';
import StarsBirthday from 'site/components/StarsBirthday';

import {
  Context,
  AdPlace2,
  AdPlace3,
  Parallax,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  Listing3,
  Listing4,
  ListingSpec,
} from 'site/components/Ads/mobile';

import NewsFeed from './news';
import WishList from './wishlist';
import VideoDrumRaker from './videoDrumRaker';
import Trends from './trends';
import GalleryDrumRaker from './galleryDrumRaker';
import Horoscope from './horoscope';

import mainTopicsQueries from './mainTopics';


function MainPage(props) {
  const {
    isMobile,
    history,
  } = props;

  const {
    data: [
      mainTopics,
      wishlist,
      news,
      videoTopics,
      galleryTopics,
      horoscopeTopics,
      trends,
      firstColumnTopics,
      secondColumnTopics,
    ],
    isLoading,
  } = mainTopicsQueries({ isMobile, history });

  if (isLoading) return <PageLoader />;

  return (
    <Page>
      <AdWrapper bottom={isMobile ? BLOCK_VERTICAL_MOBILE : BLOCK_VERTICAL}>
        <PageIndent>
          <ColumnLayout
            rightColumn={(
              <Fragment>
                <Bouesque />
                <VerticalIndent />
                <AdPlace2 />
                <StarsBirthday />
                <VerticalIndent />
                <Column2ListTopics content={secondColumnTopics} />
                <AdPlace3 />
              </Fragment>
            )}
          >
            <MainListTopics mainTopics={mainTopics} />
            <VerticalIndent />

            <Mobile>
              <NegativeMobileSideIndent>
                <Listing1 />
              </NegativeMobileSideIndent>
            </Mobile>

            <Column1ListTopics content={firstColumnTopics} />
            <VerticalIndent />

            <Desktop>
              <SuperFooter />
            </Desktop>
            <Mobile>
              <Column2ListTopics content={secondColumnTopics} />
              <VerticalIndent />
            </Mobile>


            <NewsFeed content={news} />
            <VerticalIndent />
            <Link to='/news' type='secondary'>
              <SectionTitle level={5}>Все новости →</SectionTitle>
            </Link>
            <VerticalIndent />
            <Mobile>
              <ListingSpec />
              <StarsBirthday />
              <VerticalIndent />
            </Mobile>
          </ColumnLayout>

          <Desktop>
            <Section>
              <SideIndent />
              <Block>
                <Parallax />
              </Block>
              <SideIndent />
            </Section>
          </Desktop>

          <Mobile>
            <Listing2 />
          </Mobile>
          <WishList content={wishlist} />
          <VerticalIndent />
          <Mobile>
            <Listing3 />
          </Mobile>
        </PageIndent>

        <ThemeSwitch name='dark'>
          <Desktop>
            <VideoDrumRaker content={videoTopics} />
          </Desktop>
          <Mobile>
            <VerticalIndent />
            <SkewedContainer color='#000'>
              <Section>
                <SideIndent />
                <Block>
                  <VideoDrumRaker content={videoTopics} />
                </Block>
              </Section>
            </SkewedContainer>
            <VerticalIndent />
          </Mobile>
        </ThemeSwitch>

        <PageIndent>
          <Mobile>
            <Listing4 />
          </Mobile>

          <Desktop>
            <Trends content={trends} />
            <VerticalIndent />
          </Desktop>
        </PageIndent>

        <Mobile>
          <Section>
            <SideIndent />
            <Block>
              <Trends content={trends} />
              <VerticalIndent />
            </Block>
          </Section>
        </Mobile>

        <ThemeSwitch name='dark'>
          <Desktop>
            <GalleryDrumRaker content={galleryTopics} />
          </Desktop>
          <VerticalIndent />
          <Mobile>
            <VerticalIndent />
            <SkewedContainer color='#000'>
              <Section>
                <SideIndent />
                <Block>
                  <GalleryDrumRaker content={galleryTopics} />
                </Block>
              </Section>
            </SkewedContainer>
          </Mobile>
          <VerticalIndent />
        </ThemeSwitch>

        <PageIndent>
          <Mobile>
            <Listing4 />
          </Mobile>
          <Desktop>
            <Context />
          </Desktop>

          <Horoscope content={horoscopeTopics} />
          <VerticalIndent />
          <Popular />
          <VerticalIndent />
        </PageIndent>
      </AdWrapper>
    </Page>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(MainPage);
