import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';
import withBindProps from 'core/hocs/withBindProps';

import Feed from 'core/components/Feed';
import { Indent } from 'core/components/Wrappers';

import Card1 from 'site/cards/Card1';
import { Card4Type1 } from 'site/cards/Card4';
import { Card3Type5 } from 'site/cards/Card3';
import { Native1Card4 } from 'site/components/Ads/desktop';


const HeroCard = withBindProps({ highPriorityImage: true })(Card1);

export default function Content({ mainTopics, isDesktop }) {
  if (!mainTopics) return null;

  const mainCard = mainTopics.slice(0, 1);
  const smallCards = mainTopics.slice(1);

  return (
    <Fragment>
      {mainCard.length > 0 &&
        <Feed
          heroCard={HeroCard}
          content={mainCard}
          card={Card1}
          dataQa='main-list'
        />
      }
      <Indent top={isDesktop ? 0 : 15} />
      {smallCards.length > 0 &&
        <Feed
          content={smallCards}
          card={isDesktop ? Card4Type1 : Card3Type5}
          interitemSpacing={15}
          columns={smallCards.length}
          cardsReplacement={{
            1: withReplaceCardWithAd(Native1Card4, isDesktop),
          }}
        />
      }
    </Fragment>
  );
}

Content.propTypes = {
  isDesktop: PropTypes.bool,
  mainTopics: PropTypes.array,
};
