import { compose, defaultProps, withProps } from 'core/libs/recompose';

import withBindProps from 'core/hocs/withBindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import card4Styles from './styles/card4';
import card4Type4Styles from './styles/card4-type4';
import card5Styles from './styles/card5';

export const Billboard = withBindProps({
  name: 'Billboard',
  blockId: '432500202',
  height: 250,
  options: {
    p1: 'btqzw',
    p2: 'y',
    pct: 'c',
  },
})(AdBillboard);

export const Background = withBindProps({
  name: 'Background',
  blockId: '432500174',
  options: {
    p1: 'btrhw',
    p2: 'enhl',
    pct: 'c',
  },
})(Ad);

export const Fullscreen = withBindProps({
  name: 'Fullscreen',
  blockId: '432500182',
  options: {
    p1: 'btqzu',
    p2: 'emiu',
    pct: 'a',
    pli: 'b',
    plp: 'c',
    pop: 'a',
  },
})(Ad);

export const Shtorka = withBindProps({
  name: 'Shtorka',
  blockId: '434466592',
  options: {
    p1: 'bvney',
    p2: 'exne',
    pct: 'a',
  },
})(Ad);

export const AdPlace2 = withBindProps({
  name: '240x400',
  width: 300,
  height: 600,
  stickyTemp: 3,
  blockId: '432500200',
  options: {
    p1: 'btqzs',
    p2: 'emhk',
    pct: 'c',
  },
})(Ad);

export const AdPlace3 = withBindProps({
  name: '240x400_2nd',
  width: 300,
  height: 600,
  lazy: true,
  blockId: '432500194',
  options: {
    p1: 'btqzt',
    p2: 'ewqs',
    pct: 'c',
  },
})(Ad);

export const Ad240x200 = withBindProps({
  name: '240x200',
  width: 300,
  lazy: true,
  blockId: '433330508',
  options: {
    p1: 'buxcu',
    p2: 'ewzd',
    pct: 'c',
  },
})(Ad);

export const Context = compose(
  withBindProps({
    name: 'Context',
    lazy: true,
    height: 80,
    blockId: '432500230',
    options: {
      p1: 'btrat',
      p2: 'ewzc',
      pct: 'a',
    },
  }),
  defaultProps({ count: 1 }),
  withProps(({ count }) => ({ manualPuids: { puid44: `context_item${count}` } })),
)(Ad);

export const Sponsored = compose(
  withBindProps({
    name: 'Sponsored',
    blockId: '444721402',
    options: {
      p1: 'bwuhr',
      p2: 'fomx',
      pct: 'a',
    },
  }),
  sponsoredStyles,
)(Ad);

export const SuperFooter = withBindProps({
  name: '100%x240',
  width: 600,
  height: 240,
  lazy: true,
  blockId: '432500196',
  options: {
    p1: 'btqzr',
    p2: 'fcuz',
    pct: 'c',
  },
})(Ad);

export const Parallax = withBindProps({
  name: 'Parallax',
  width: 928,
  height: 250,
  lazy: true,
  blockId: '444721058',
  options: {
    p1: 'bwuhq',
    p2: 'fhoe',
    pct: 'a',
  },
})(Ad);

export const AdCenter = withBindProps({
  name: 'Center',
  width: 600,
  height: 240,
  blockId: '444720174',
  lazy: true,
  options: {
    p1: 'bwuhp',
    p2: 'fomw',
    pct: 'a',
  },
})(Ad);

export const InRead = withBindProps({
  name: 'inRead',
  width: 600,
  height: 300,
  blockId: '432500170',
  options: {
    p1: 'btqzv',
    p2: 'fcvb',
    pct: 'a',
  },
})(Ad);

const native1Props = {
  name: 'Native1',
  blockId: '432500006',
  options: {
    p1: 'bwjwr',
    p2: 'fhzr',
    pct: 'a',
  },
};

const native2Props = {
  name: 'Native2',
  blockId: '432500004',
  lazy: true,
  options: {
    p1: 'bwjws',
    p2: 'fhzs',
    pct: 'a',
  },
};

export const Native1Card4 = compose(
  withBindProps(native1Props),
  card4Styles,
)(Ad);

export const Native1Card5 = compose(
  withBindProps(native1Props),
  card5Styles,
)(Ad);

export const Native2Card4 = compose(
  withBindProps(native2Props),
  card4Styles,
)(Ad);

export const Native2Card5 = compose(
  withBindProps(native2Props),
  card5Styles,
)(Ad);

// aka Native 300x300
export const Native3 = compose(
  withBindProps({
    name: 'Native3 styled as Highlight-wrapped Card3Type1',
    width: 350,
    height: 300,
    blockId: '456579366',
    lazy: true,
    options: {
      p1: 'bxkts',
      p2: 'fjgk',
      pct: 'a',
    },
  }),
  card4Type4Styles,
)(Ad);

export const NativeFooter = compose(
  withBindProps({
    name: 'NativeFooter',
    lazy: true,
    blockId: '433694846',
    options: {
      p1: 'buolr',
      p2: 'fhzt',
      pct: 'a',
    },
  }),
  card4Styles,
)(Ad);

export const InGallery = withBindProps({
  name: 'InGallery',
  blockId: '480358629',
  disableAutoInit: true,
})(Ad);
