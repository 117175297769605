import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import { Mobile, Desktop } from 'core/components/breakpoint';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import VideoIcon from 'site/icons/Video';
import PhotoIcon from 'site/icons/Photo';

import styles from './index.styl';

import relationshipsPropTypes, { imagePropTypes } from 'core/utils/prop-types/relationships';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import SectionTitle from 'site/components/SectionTitle';

import { BLOCK_HORIZONTAL } from 'site/components/Indents';

const linkSecondary = ({ theme }) => theme.controls.link.secondary;

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'topic_type',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

const sectionTitles = {
  video: 'Видео',
  news: 'Новость',
  article: 'Статья',
  gallery: 'Фото',
};

function Card2(props) {
  const {
    content,
    breakpoint,
    theme,
    isDesktop,
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const isVideo = topicType === 'video';
  const isGallery = topicType === 'gallery';
  const aggregatorLink = isGallery ? '/photo' : `/${topicType}`;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .hero-link
          left ${isMobile ? 0 : BLOCK_HORIZONTAL + 'px'}

        .card-link-scoped
          font-family ${theme.fonts.display}

          :global([id*="$"])
            transition fill ${theme.animations.hover}

          :global([id*="!"])
            transition stroke ${theme.animations.hover}

          &:hover
            :global([id*="$"])
              fill ${linkSecondary({ theme }).hover.color}

            :global([id*="!"])
              stroke ${linkSecondary({ theme }).hover.color}
      `}</style>
    </scope>
  );

  return (
    <div
      className={cx(
        styles.card,
        styles[`card--layout-${breakpoint}`],
      )}
    >
      <style jsx>{`
        .content
          padding-left ${isMobile ? 0 : BLOCK_HORIZONTAL + 'px'}
          padding-right ${isMobile ? 0 : BLOCK_HORIZONTAL + 'px'}

        .hero-fade
          background ${theme.colors.content}

        .headline
          font-family ${theme.fonts.text}
      `}</style>

      <SectionTitle>
        <Link
          /**
          * Ссылка на страницу агрегатор с фильтрацией топиков по типу.
          * Данная страница в текущей версии WMJ не реализована.
          * Пока решили что URL является типом топика (/video, /gallery)
          */
          to={aggregatorLink}
          type='secondary'
          className={cx(
            styles['card-inner_link'],
            scoped.wrapClassNames('hero-link'),
          )}
        >

          {sectionTitles[topicType]}
        </Link>
      </SectionTitle>
      <Link
        to={link}
        type='secondary'
        data-qa='card2'
        className={cx(
          styles['card-link'],
          scoped.wrapClassNames('card-link-scoped')
        )}
      >
        {isDesktop && (
          <div className={styles['card-hero']} data-qa='card2-hero'>
            <Image
              src={cover}
              maxWidth={1000}
              previewSrc={previewCover}
              position='50% 0'
            />
            <div
              className={cx(
                styles['card-hero_fade'],
                'hero-fade',
              )}
            />
          </div>
        )}
        <div className={cx(
          styles['card-content'],
          'content',
        )} data-qa='card2-content'
        >
          <div
            className={cx(
              styles['card-content_block'],
              styles['card-title'],
              styles['card-title--hidden'],
              scoped.wrapClassNames('hero-link'),
            )}
          >
            <SectionTitle data-qa='card2-section-title'>
              {sectionTitles[topicType]}
            </SectionTitle>
          </div>
          <div className={styles['card-content_block']}>
            <Mobile>
              <Image
                src={cover}
                previewSrc={previewCover}
                position='50% 0'
              />
            </Mobile>
          </div>
          <div
            className={cx(
              styles['card-content_block'],
              styles['card-headline'],
              'headline',
            )}
            data-qa='card2-headline'
          >
            <Desktop>
              {isVideo && (
                <VideoIcon className={styles.icon} data-qa='card2-video-icon' />
              )}
              {isGallery && (
                <PhotoIcon className={styles.icon} data-qa='card2-photo-icon' />
              )}
            </Desktop>
            <MarkdownWrapper inline>
              {listHeadline || headline}
            </MarkdownWrapper>
          </div>
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

Card2.propTypes = {
  type: PropTypes.oneOf([0]),
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

const Card = withCard(Card2);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
