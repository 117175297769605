import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import {
  MOBILE,
  TABLET,
  DESKTOP,
} from 'core/components/breakpoint/values';

import styles from './index.styl';
import { resolveStyles } from 'core/utils/styles';

const styleResolver = resolveStyles(styles);

const skewHeights = {
  [MOBILE]: 40,
  [TABLET]: 160,
  [DESKTOP]: 180,
};

/**
 * Компонент реализующий блок со скошенными гранями для главной страницы.
 * @author Anton Ignatov <a.ignatov@rambler-co.ru>
 */
class SkewedContainer extends PureComponent {
  static propTypes = {
    /**
     * Цвет фона скошенного прямоугольника.
     */
    color: PropTypes.string,

    /**
     * Флаг отвечающий за обрезание контента по скошенному прямоугольнику.
     */
    overflow: PropTypes.bool.isRequired,

    /**
     * Изначальная ширина для расчета угла скоса.
     * Будет автоматически пересчитываться в рантайме.
     */
    initialWidth: PropTypes.number.isRequired,

    /**
     * Если в качестве `children` передана функция, то ей будет передан объект
     * с информации о скосе контейнера.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  };

  static defaultProps = {
    initialWidth: 1280,
    overflow: false,
  };

  constructor(props) {
    super(props);

    const {
      breakpoint,
      initialWidth,
    } = this.props;

    const width = initialWidth;
    const height = skewHeights[breakpoint];

    this.state = {
      width,
      height,
      angle: calcAngle(height, width),
    };
  }

  componentDidMount() {
    this.updateAngle();
    window.addEventListener('resize', this.updateAngle);
  }

  componentDidUpdate() {
    this.updateAngle();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateAngle);
  }

  updateAngle = () => {
    const width = this.container.offsetWidth;
    const height = skewHeights[this.props.breakpoint];

    this.setState({
      width,
      height,
      angle: calcAngle(height, width),
    });
  };

  render() {
    const { height, angle } = this.state;

    const {
      color,
      overflow,
      children,
      breakpoint,
    } = this.props;

    const content = typeof(children) === 'function'
      ? children({ height, breakpoint })
      : children;

    return (
      <div
        className={styleResolver('block')}
        ref={node => (this.container = node)}
      >
        <div
          className={styleResolver('block-container')}
          style={{
            backgroundColor: color,
            margin: `${height}px 0 0`,
            transform: `skewY(-${angle}rad)`,
            overflow: overflow ? 'hidden' : undefined,
          }}
        >
          <div
            className={styleResolver('block-content')}
            style={{
              padding: `${height}px 0 0`,
              transform: `skewY(${angle}rad)`,
            }}
          >
            {content}
          </div>
        </div>
      </div>
    );
  }
}

SkewedContainer.propTypes = {
  breakpoint: breakpointPropTypes(),
};

// Возвращает градус между катетом и гипотенузой в радианах.
function calcAngle(a, b) {
  return Math.atan(a / b);
}

export default withBreakpoint(SkewedContainer);
