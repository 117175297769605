import React from 'react';
import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import MarkdownWrapper from 'core/components/MarkdownWrapper';
import H1 from 'core/components/H1';
import withTheme from 'core/hocs/withTheme';


function TopicH1({
  children,
  textAlign,
  theme,
}) {
  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          font ${theme.texts.headline.font}
          color ${theme.texts.headline.color}
      `}</style>
    </scope>
  );

  return (
    <div style={{ textAlign }} data-qa='topic-headline'>
      <H1 className={scoped.wrapClassNames('headline')}>
        <MarkdownWrapper inline>{children}</MarkdownWrapper>
      </H1>
      <scoped.styles />
    </div>
  );
}

TopicH1.propTypes = {
  textAlign: PropTypes.string,
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
};

TopicH1.defaultProps = {
  textAlign: 'left',
};

export default withTheme(TopicH1);
