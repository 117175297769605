import color from 'core/libs/color';

import withBindProps from 'core/hocs/withBindProps';
import Logo from '../components/Logo';


const icons = {
  logo: withBindProps({ themeName: 'dark' })(Logo),
};

const colors = {
  layout: '#fff',
  content: '#131313',
  active1: '#a68b5c',
  active2: '#dfc991',
  accent: '#efe5d4',
  error: '#ff3e56',
  primary: '#fff',
  footer: '#000',
  input: '#fff',
  darkBlue: '#232E3A',
  divider: color('#e5e5e5').alpha(0.2).string(),
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get placeholder2() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() {
    return this.active1;
  },
};

const gallery = {
  icons: {
    background: color(colors.content).alpha(0.5).string(),
  },
};

export default {
  colors,
  icons,
  controls: {
    gallery,
  },
};
