import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

function StoryStatusLine({ theme }) {
  const storyAtoms = theme.controls.storyCard;

  const {
    statusLine: {
      border: statusLineBorder,
      borderRadius: statusLineBorderRadius,
      indent: statusLineIndent,
    },
  } = storyAtoms;

  return (
    <div className={styles.statusLine}>
      <style jsx>{`
        .${styles.statusLine}
          border ${statusLineBorder}
          border-radius ${statusLineBorderRadius}
          margin ${statusLineIndent}

          &:after
            border-right 15px solid ${theme.colors.active2}
      `}</style>
    </div>
  );
}

StoryStatusLine.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(StoryStatusLine);
