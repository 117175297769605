import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Content from './Content';


/**
 * Компонент предназначен для отображения топиков листа `main` на главной.
 */

function MainListTopics({ theme, isDesktop }) {
  return (
    <Content
      theme={theme}
      isDesktop={isDesktop}
    />
  );
}

MainListTopics.propTypes = {
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(withBreakpoint(MainListTopics));
