import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';

import useRequest from 'core/hooks/useRequest';

import withBindProps from 'core/hocs/withBindProps';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';

import Feed from 'core/components/Feed';
import Rubric from 'core/components/Rubric';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import { Mobile, Desktop } from 'core/components/breakpoint';
import H5 from 'core/components/H5';
import PageLoader from 'core/components/Loader/PageLoader';

import { filterRequiredParams } from 'core/utils/api';

import {
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import SectionTitle from 'site/components/SectionTitle';
import Boroda from 'site/components/Boroda';
import Divider from 'site/components/Divider';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Skeleton from './Skeleton';

import { splitInGroups } from 'site/utils';

import { Card3Type1 } from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';
import {
  Native1Card5,
  Native2Card5,
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  Listing3,
  ListingSpec,
} from 'site/components/Ads/mobile';

import { TOPIC_GROUPS, TOPICS_LIMIT } from 'site/constants';


// Собираем все необходимые параметры для запроса и отрисовки карточек.
const INCLUDE = filterRequiredParams([Card5], 'include');
const FIELDS = filterRequiredParams([Card5, Card3Type1], 'fields');

const RubricCard = withBindProps({ imageMaxWidth: 394 })(Card5);

function RubricPage(props) {
  const { isDesktop, location, match, history } = props;

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({
    queries: [
      rawRubricQuery({ match, history }),
      rawTopicsQuery({
        include: INCLUDE,
        fields: FIELDS,
        limit: TOPICS_LIMIT,
        location,
        match,
        history,
      }),
    ],
  });

  const commonFeedProps = {
    card: isDesktop ? RubricCard : Card3Type1,
    interitemSpacing: 30,
    columns: 2,
    grid: true,
  };

  if (rubricIsLoading) return <Skeleton />;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          subrubricsColumnCount={3}
          titleComponent={({ children }) => (
            <Fragment>
              <SectionTitle data-qa='page-title' htmlTag={'h1'}>{children}</SectionTitle>
              <Divider />
            </Fragment>
          )}
          limit={TOPICS_LIMIT}
          showSubrubrics
          isLoading={topicsAreLoading || rubricIsLoading}
        >
          {({ topics }) => {
            if (topicsAreLoading) return <PageLoader />;
            if (!topics?.length) return <H5>Материалов по теме не найдено</H5>;

            const [
              first,
              second,
              third,
              fourth,
            ] = splitInGroups(topics, TOPIC_GROUPS);

            return (
              <Fragment>
                <VerticalIndent />
                <Feed
                  {...commonFeedProps}
                  content={first}
                  cardsReplacement={{
                    2: withReplaceCardWithAd(Native1Card5, isDesktop),
                  }}
                />
                <VerticalIndent />
                <Desktop>
                  <SuperFooter />
                  <VerticalIndent />
                </Desktop>
                <Mobile>
                  <NegativeMobileSideIndent>
                    <Listing1 />
                  </NegativeMobileSideIndent>
                  <VerticalIndent />
                </Mobile>
                <Feed
                  {...commonFeedProps}
                  content={second}
                />
                <VerticalIndent />
                <Mobile>
                  <ListingSpec />
                  <VerticalIndent />
                </Mobile>
                <Feed
                  {...commonFeedProps}
                  content={third}
                  cardsReplacement={{
                    2: withReplaceCardWithAd(Native2Card5, isDesktop),
                  }}
                />
                <VerticalIndent />
                <Mobile>
                  <NegativeMobileSideIndent>
                    <Listing2 />
                  </NegativeMobileSideIndent>
                  <VerticalIndent />
                </Mobile>
                <Feed
                  {...commonFeedProps}
                  content={fourth}
                />
                <VerticalIndent />
                <Desktop>
                  <Context />
                  <VerticalIndent />
                </Desktop>
              </Fragment>
            );
          }}
        </Rubric>
        <VerticalIndent />
        <Mobile>
          <NegativeMobileSideIndent>
            <Listing3 />
          </NegativeMobileSideIndent>
        </Mobile>
        <VerticalIndent />
        <Boroda />
      </ColumnLayout>
    </PageIndent>
  );
}

RubricPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};


export default withBreakpoint(RubricPage);
