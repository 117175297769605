import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import { topicsQuery } from 'core/queries/topics';
import withBreakpoint from 'core/hocs/withBreakpoint';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import { resolve } from 'core/utils/env';
import resolveRelationships from 'core/utils/relationships';

import InfiniteTopic from 'core/components/InfiniteTopic';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import { Mobile } from 'core/components/breakpoint';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';
import RandomizedRender from 'core/components/RandomizedRender';

import TopicFooter from 'site/components/TopicFooter';
import { TopBanner } from 'site/components/Ads/mobile';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { PartnersHorizontal } from 'site/components/Partners';
import Recommender from 'site/components/Recommender';
import { VerticalIndent } from 'site/components/Indents';
import RelatedGalleriesContext from 'site/components/RelatedGalleriesContext';

import { coverAsGallery } from 'site/utils';

import Card9 from 'site/cards/Card9';

import StoryTopic from './content/story';
import HoroscopeTopic from './content/horoscope';
import WishlistTopic from './content/wishList';
import PremiumTopic from './content/premium';

import TopicSkeleton from './Skeleton';


const CARDS_COUNT = 3;

const horoscopeShape = {
  horoscope: {
    zodiac: '',
  },
};

const galleryRelationships = resolveRelationships(
  ['content'],
  {},
  {
    content: { widgets: [] },
  },
);

const horoscopeRelationships = resolveRelationships(
  ['horoscope'],
  {},
  horoscopeShape,
);

const topicTypeMapping = {
  news: StoryTopic,
  article: StoryTopic,
  gallery: StoryTopic,
  video: StoryTopic,
  horoscope: HoroscopeTopic,
  product: WishlistTopic,
  // TODO: удалить или замапить specproject
  specproject: noopComponent('specproject'),
  premium: PremiumTopic,
};

const excludeTopicTypes = ['product', 'horoscope'];


function noopComponent(type) {
  return function Noop() {
    return (
      <div>
        Не поддерживаемый тип топика: {type}
      </div>
    );
  };
}

function topicRenderer(topic, enhancedTopic, additionalTopics) {
  const {
    content,
    infinityIndex,
  } = enhancedTopic;

  const {
    attributes: {
      link,
      topic_type: topicType,
      is_premium: isPremium,
    },
  } = content;

  const Unsupported = noopComponent(topicType);
  const type = isPremium ? 'premium' : topicType;
  const Content = topicTypeMapping[type] || Unsupported;

  /**
   * Если сетка отличается от стандартной двухколоночной,
   * определяем вспомогательные лейауты.
   * Это статические свойства импортируемого компонента.
   *
   * Для примера, в топиках "Премиальная статься":
   * (Breadcrumbs, заголовок, реклама, Socializer
   * и картинка - cover) являются первым блоком, на всю ширину
   * страницы.
   *
   * Далее идет двухколоночный макет с контентом и блоками
   * рекламы в сайдбаре.
   */
  const {
    BeforeContent,
  } = Content.placeholders || {};

  const hideTopicTags = excludeTopicTypes.includes(topicType);
  const showTopicFooter = topicType !== 'product';

  const isScrollToSite = checkScrollToSiteInTopic(enhancedTopic);

  return (
    <PageIndent>
      {infinityIndex > 0 && (
        <Mobile>
          <TopBanner lazy />
          <VerticalIndent />
        </Mobile>
      )}
      {BeforeContent && (
        <BeforeContent content={content} />
      )}
      <ColumnLayout rightColumn={<InnerSideColumn />} sideColumnIndent='75px'>
        <Content
          content={content}
          topicLink={link}
          {...additionalTopics}
        />
        {showTopicFooter && (
          <TopicFooter
            hideTopicTags={hideTopicTags}
            hideSuperFooter={isScrollToSite}
            content={content}
          />
        )}
        {!isScrollToSite && (
          <>
            <VerticalIndent />
            {infinityIndex % 2 !== 0 ? (
              <RandomizedRender probabilities={[50, 50]}>
                <Recommender />
                <PartnersHorizontal />
              </RandomizedRender>
            ) : (
              <PartnersHorizontal />
            )}
          </>
        )}
      </ColumnLayout>
    </PageIndent>
  );
}

function TopicPage({ isDesktop, location, match, history }) {
  const { data: currentTopic, isLoading: topicIsLoading } = useRequest(topicQuery({
    location,
    match,
    history,
  }));

  const { zodiac } = horoscopeRelationships(currentTopic).horoscope;
  const isTopicTypeGallery = currentTopic?.attributes?.topic_type === 'gallery';
  const isTopicTypeHoroscope = currentTopic?.attributes?.topic_type === 'horoscope';

  const { data: relatedGalleries, isLoading: relatedGalleriesIsLoading } = useRequest(
    topicsQuery({
      include: filterRequiredParams([Card9], 'include', ['photo_gallery']),
      fields: filterRequiredParams([Card9], 'fields'),
      limit: CARDS_COUNT + 1,
      topic_type: 'gallery',
      plugin: JSON.stringify({
        photo_gallery: 1,
      }),
      renderError: () => ({}),
    }, {
      enabled: isTopicTypeGallery || coverAsGallery(currentTopic),
      select: result => {
        let photoGallery;
        if (isTopicTypeGallery) {
          photoGallery = currentTopic.relationships?.photo_gallery?.data;
        } else if (coverAsGallery(currentTopic)) {
          const { widgets } = galleryRelationships(currentTopic).content;
          photoGallery = widgets[0];
        }

        if (!photoGallery) return null;

        /**
         * Отфильтровываем из 5 полученных топиков типа `gallery` тот
         * в котором может содержаться текущая галерея и из полученного списка
         * берем 4 (см. `CARDS_COUNT`) самых новых топика для отрисовки
         * в `Mjolnir`.
         */
        const data = result.data
          .filter(topic => topic.relationships.photo_gallery.data.id !== photoGallery.id)
          .slice(0, CARDS_COUNT);

        return denormalizeData({ data, included: result.included });
      },
    }),
  );

  const { data: sameZodiacTopics } = useRequest(
    topicsQuery({
      include: 'image,person_meta',
      topic_type: 'star',
      plugin: JSON.stringify({ person_meta: { zodiac } }),
      renderError: () => ({}),
    }, {
      enabled: isTopicTypeHoroscope,
      select: denormalizeData,
    }),
  );

  if (topicIsLoading) return <TopicSkeleton />;

  return (
    <RelatedGalleriesContext.Provider value={{ relatedGalleries, isLoading: relatedGalleriesIsLoading }}>
      <InfiniteTopic
        content={currentTopic}
        rcmBlockId={resolve({
          '*': '42c78d47bdac4c15a34a317d0a985e4a',
          'production': isDesktop ? 'c683b2a3b45a4dc09232000439b14734' : '57d532b797b54caab159dc5f436907f9',
        })}
      >
        {(t, e) => topicRenderer(t, e, { sameZodiacTopics })}
      </InfiniteTopic>
    </RelatedGalleriesContext.Provider>
  );
}

TopicPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(TopicPage);
