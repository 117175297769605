import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import Drum from 'core/components/Drum';
import withBindProps from 'core/hocs/withBindProps';

import SectionTitle from 'site/components/SectionTitle';
import Card6 from 'site/cards/Card6';
import { Card4Type3 } from 'site/cards/Card4';

import styles from './index.styl';


const HeaderComponent = withBindProps({ align: 'center', className: styles.sectionTitle })(SectionTitle);

const Trends = ({ content, isDesktop }) => (
  <Drum
    content={content}
    title='Тренды сезона'
    headerComponent={HeaderComponent}
    card={isDesktop ? Card6 : Card4Type3}
    itemWidth={195}
    itemWidthMobile={267}
    spaceBetween={isDesktop ? 0 : 15}
    dataQa='trends-list'
  />
);

Trends.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Trends);
