import PropTypes from 'prop-types';

import { ContentVerticalIndent } from 'site/components/Indents';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import Socializator from 'core/components/Socializator';
import { Indent } from 'core/components/Wrappers';
import withBreakpoint from 'core/hocs/withBreakpoint';


import TopicContent from 'site/components/TopicContent';
import TopicHeader from 'site/components/TopicHeader';
import TopicMedia from 'site/components/TopicMedia';
import Sponsored from 'site/components/Ads/Sponsored';

import styles from './index.styl';

/**
 * Поскольку разметка для топиков типа: article, news, gallery, video
 * общая, создан единый компонент StoryTopic
 */
function StoryTopic({ content, isMobile }) {
  return (
    <div data-qa='topic'>
      <TopicHeader content={content} />
      <Sponsored />
      <div className={styles.socializator}>
        <Socializator />
      </div>
      <Indent top={isMobile ? 10 : 24} bottom={isMobile ? 24 : 36}>
        <TopicMedia content={content} />
      </Indent>
      <ContentVerticalIndent />
      <TopicContent content={content} />
    </div>
  );
}

StoryTopic.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(StoryTopic);
