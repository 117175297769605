import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';
import withBindProps from 'core/hocs/withBindProps';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import VideoIcon from 'site/icons/Video';

import styles from './index.styl';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import mapTypes from './types';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'topic_type',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card5(props) {
  const {
    type,
    content,
    imageMaxWidth,
    imageMaxHeight,
    utm,
  } = props;

  if (!content) return null;

  const {
    showAltHeadline,
  } = mapTypes[type] || {};

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    remote_image: remoteImage,
  } = content.extra || {};

  const isVideo = topicType === 'video';
  const to = utm ? link + utm : link;
  return (
    <div className={cx(
      'card5',
      styles.card5,
      showAltHeadline && styles.withAltHeadline,
    )} data-qa='card5'
    >
      <Link
        to={to}
        type='secondary'
        className={styles.link}
        data-qa='card5-link'
      >
        <div className={styles.image}>
          <Image
            {...cover && { src: cover }}
            {...remoteImage && { url: remoteImage }}
            {...cover && { previewSrc: previewCover }}
            position='50% 0'
            maxWidth={imageMaxWidth}
            maxHeight={imageMaxHeight}
            aspectRatio={1.5}
          />
          {isVideo && (
            <VideoIcon
              width='32'
              height='32'
              className={styles.icon}
              data-qa='card5-video-icon'
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.headline} data-qa='card5-headline'>
            {listHeadline || headline}
          </div>
          {showAltHeadline && altHeadline && (
            <div className={styles.altHeadline} data-qa='card5-alt-headline'>
              {altHeadline}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

Card5.propTypes = {
  imageMaxWidth: PropTypes.number,
  imageMaxHeight: PropTypes.number,
  type: PropTypes.oneOf([0, 1]),
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  utm: PropTypes.string,
};

Card5.defaultProps = {
  type: 0,
  imageMaxHeight: 250,
};

const Card = withCard(Card5);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const Card5Type1 = withBindProps({ type: 1 })(Card);

export default Card;
