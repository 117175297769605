import React from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

/**
 * Компонент создан из-за необходимости вертикально отцентрировать цифру,
 * чтобы свободное место поровну распределялось сверху и снизу от символа
 *
 * Пример тут: http://jsbin.com/daconoqugi/edit?css,output
 *
 * Рассчитываем vertical-align на основе положения базовой линии (пропорции верхней/нижней частей)
 *
 * Все подробно описано в этой статье:
 * http://css-live.ru/css/metriki-shrifta-line-height-vertical-align.html
 */

/**
 * Метрики для шрифта ALS Mirta Regular
 *
 * Получить метрики шрифта можно здесь:
 * https://fontforge.github.io/en-US/
 * https://opentype.js.org/font-inspector.html
 */
const fontMetrics = {
  emSquare: 1,
  capitalHeight: 0.701,
  descender: 0.25,
  ascender: 0.75,
  linegap: 0,
};

function NumberEqualHeight(props) {
  const {
    capitalHeight,
    lineHeight,
    theme,
    weight,
  } = props;

  let { value } = props;

  if (typeof value === 'number') value = `${value}`;
  if (!value) return null;


  const distanceBottom = fontMetrics.descender;
  const distanceTop = fontMetrics.ascender - fontMetrics.capitalHeight;
  const computedFontSize = capitalHeight / fontMetrics.capitalHeight;

  const valign = (distanceBottom - distanceTop) * computedFontSize;
  const computedLineHeight = (lineHeight * capitalHeight) - valign;

  return (
    <div style={{
      color: theme.colors.primary,
      font: `${weight} ${computedFontSize}px/${computedLineHeight}px ${theme.fonts.nuance}`,
    }}
    >
      <span style={{ verticalAlign: `${valign * -1}px` }}>{value}</span>
    </div>
  );
}

NumberEqualHeight.propTypes = {
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Необходимая высота глифа по макету
   */
  capitalHeight: PropTypes.number,
  lineHeight: PropTypes.number,
  theme: PropTypes.object,
};

NumberEqualHeight.defaultProps = {
  weight: 'normal',
  lineHeight: 1,
};

export default withTheme(NumberEqualHeight);
