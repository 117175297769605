import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withBreakpoint from 'core/hocs/withBreakpoint';

import { Indent } from 'core/components/Wrappers';

import styles from './index.styl';

function Horoscope({ isMobile }) {
  return (
    <Fragment>
      <div className={styles.title}>
        <div className={cx('lb-skeleton', styles.titleIcon)} />
        <span className={styles.titleContent}>
          <div className={cx('lb-skeleton', styles.titleHeadline)} />
          <div className={cx('lb-skeleton', styles.titlePeriod)} />
        </span>
      </div>
      <Indent top={isMobile ? 20 : 40} />
      <div className={styles.breadcrumbs}>
        <div className={cx('lb-skeleton', styles.breadcrumb)} />
        <div className={cx('lb-skeleton', styles.breadcrumb)} />
        <div className={cx('lb-skeleton', styles.breadcrumb)} />
        <div className={cx('lb-skeleton', styles.breadcrumb)} />
        <div className={cx('lb-skeleton', styles.breadcrumb)} />
      </div>
      <Indent top={isMobile ? 20 : 40} />
      <div className={cx('lb-skeleton', styles.content)} />
    </Fragment>
  );
}

Horoscope.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Horoscope);
