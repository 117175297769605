import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import { Desktop } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import TopicFooter from 'core/components/TopicFooter';
import { Indent } from 'core/components/Wrappers';

import Sponsored from 'site/components/Ads/Sponsored';
import {
  SuperFooter,
} from 'site/components/Ads/desktop';

import { Fragment } from 'react';

function TopicFooterWrap(props) {
  const {
    hideTopicTags,
    hideSuperFooter,
    content,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
          },
        },
      },
    },
  } = props;

  return (
    <Fragment>
      <TopicFooter
        content={content}
        hrefBuilder={(slug, tagType) => (
          tagType === 'star'
            ? `/stars/${slug}`
            : `/label/${slug}`
        )}
        hideTopicTags={hideTopicTags}
      />
      <Indent bottom={spacing} />
      {!hideSuperFooter && (
        <AdWrapper bottom={spacing}>
          <Desktop>
            <SuperFooter />
          </Desktop>
        </AdWrapper>
      )}
      <Sponsored />
    </Fragment>
  );
}

TopicFooterWrap.propTypes = {
  hideTopicTags: PropTypes.bool,
  hideSuperFooter: PropTypes.bool,
  content: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(TopicFooterWrap);
