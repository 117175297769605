import useRequest from 'core/hooks/useRequest';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { authorQuery, topicsQuery } from 'core/queries/authorPage';

import withBindProps from 'core/hocs/withBindProps';
import AuthorPage from 'core/components/AuthorPage';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import Card5 from 'site/cards/Card5';
import { Card3Type1 } from 'site/cards/Card3';

import SectionTitle from 'site/components/SectionTitle';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { VerticalIndent } from 'site/components/Indents';


const breakpointCards = {
  [DESKTOP]: withBindProps({ imageMaxWidth: 426 })(Card5),
  [MOBILE]: Card3Type1,
};

const TopicsTitle = withBindProps({ level: 4 })(SectionTitle);
const AllAuthorsLink = withBindProps({ level: 6 })(SectionTitle);

function Author({ breakpoint, ...routerProps }) {
  const results = useRequest({
    queries: [
      authorQuery(routerProps),
      topicsQuery({
        include: 'image,rubric',
        limit: 10,
        ...routerProps,
      }),
    ],
  });

  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumnShort />}>
        <AuthorPage
          grid
          author={author}
          topics={topics}
          columns={2}
          card={breakpointCards[breakpoint]}
          interitemSpacing={20}
          isLoading={authorIsLoading || topicsAreLoading}
          topicsTitleComponent={TopicsTitle}
          allAuthorsLinkComponent={AllAuthorsLink}
        />
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

Author.propTypes = {
  /** @ignore */
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(Author);
