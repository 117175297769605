import React, { useContext } from 'react';

import Mjolnir from 'core/components/Mjolnir';
import { Mobile, Desktop } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';

import withBindProps from 'core/hocs/withBindProps';

import RelatedGalleriesContext from 'site/components/RelatedGalleriesContext';

import Card9 from 'site/cards/Card9';

import styles from './index.styl';


const HeroCard = withBindProps({ type: 1, imageMaxWidth: 778 })(Card9);
const GalleriesCard = withBindProps({ imageMaxWidth: 374 })(Card9);

export default function RelatedGalleries() {
  const { relatedGalleries: content, isLoading } = useContext(RelatedGalleriesContext);

  if (isLoading) return <PageLoader />;
  if (!content) return null;

  return (
    <div className={styles.container}>
      <Mobile>
        <Card9 content={content[0]} imageMaxWidth={700} />
      </Mobile>
      <Desktop>
        <Mjolnir
          interitemSpacing={30}
          content={content}
          heroCard={HeroCard}
          card={GalleriesCard}
        />
      </Desktop>
    </div>
  );
}
