import { compose, setStatic } from 'core/libs/recompose';
import { Block, Section } from 'core/components/Grid';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import Socializator from 'core/components/Socializator';
import { Indent } from 'core/components/Wrappers';
import withBreakpoint from 'core/hocs/withBreakpoint';


import TopicContent from 'site/components/TopicContent';
import TopicHeader from 'site/components/TopicHeader';
import TopicMedia from 'site/components/TopicMedia';
import Sponsored from 'site/components/Ads/Sponsored';

import {
  SideIndent,
  ContentVerticalIndent,
} from 'site/components/Indents';

function PremiumTopic({ content }) {
  return (
    <TopicContent content={content} />
  );
}

PremiumTopic.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};

PremiumTopic.placeholders = {
  // eslint-disable-next-line react/prop-types
  BeforeContent({ content, isMobile }) {
    return (
      <Section qaid='topic'>
        <SideIndent />
        <Block>
          <TopicHeader content={content} center />
          <Sponsored />
          <ContentVerticalIndent />
          <div style={{ textAlign: 'center' }}>
            <Socializator />
          </div>
          <Indent top={24} bottom={isMobile ? 24 : 36}>
            <TopicMedia content={content} />
          </Indent>
        </Block>
        <SideIndent />
      </Section>
    );
  },
};

export default compose(
  setStatic('placeholders', PremiumTopic.placeholders),
  withBreakpoint,
)(PremiumTopic);
