import PropTypes from 'prop-types';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';
import withBindProps from 'core/hocs/withBindProps';

import Link from 'core/components/Link';
import Divider from 'core/components/Divider';
import ArrowRight from 'core/components/Icon/ArrowRight';

import relationshipsPropTypes, {
  horoscopePropTypes,
} from 'core/utils/prop-types/relationships';

import cutDown from 'core/utils/cut-down';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { dateFormat } from 'core/utils/dates';
import resolveRelationships from 'core/utils/relationships';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = [
  'horoscope',
  'content',
];

const requiredPayloadFields = [
  'link',
];

const relationships = resolveRelationships(requiredPayloadImports, {});

const headlineFont = (themeTexts, type, isMobile) => {
  if (type === 1) {
    return isMobile ? themeTexts.title3.font : themeTexts.title2.font;
  }

  return themeTexts.title3.font;
};


function Card8(props) {
  const {
    content,
    isMobile,
    type,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
  } = content.attributes;

  const {
    horoscope: {
      zodiac,
      zodiac_ru: zodiacRu,
      birth_dates: birthDates,
    },
    content: {
      widgets = [],
    },
  } = relationships(content);

  const period = birthDates.map(date => dateFormat(date, 'd MMMM')).join(' - ');

  const Icon = zodiac
    ? require(`site/components/Icon/Horoscope/${zodiac}`).default
    : () => null;

  const {
    showBody,
  } = mapTypes[type] || {};

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          margin-bottom ${isMobile ? '30px' : ''}

        .icon
          & :global([id*="$"])
            fill ${theme.colors.active2}
      `}</style>
    </scope>
  );

  return (
    <div className={cx('card8', styles.card, `_type${type}`, styles[`_type${type}`])} data-qa='card8'>
      <Link
        to={link}
        type='secondary'
        className={scoped.wrapClassNames(styles.link)}
        data-qa='card8-link'
      >
        <style jsx>{`
          .headline
            font ${headlineFont(theme.texts, type, isMobile)}

          .period
            color ${theme.texts.caption1.color}
            font ${theme.texts.caption1.font}
            :global(.mobile) &
              font 14px/1 ${theme.fonts.nuance}
              text-transform uppercase

          .${styles.iconRound}
            :global(.desktop) &
              background ${theme.colors.layout}

          .body
            color ${theme.texts.body.color}
            font ${theme.texts.body.font}
        `}</style>

        <div className={styles['card-head']}>
          <div className={styles.iconRound}>
            <Icon
              className={cx(
                styles['card-icon'],
                scoped.wrapClassNames('icon')
              )}
            />
          </div>
          <div className={styles['card-headline_wrapper']}>
            <div className={cx(styles['card-headline'], 'headline')} data-qa='card8-headline'>
              {zodiacRu}
            </div>
            <div className='period' data-qa='card8-period'>{period}</div>
          </div>
          {isMobile &&
            <div className={styles['card-arrow_wrapper']}>
              <ArrowRight className={styles['card-arrow']} />
            </div>
          }
        </div>
        {showBody &&
          <div
            className={cx(
              styles['card-body'],
              'body',
            )}
          >
            {cutDown(widgets[0].attributes.body, 350)}
          </div>
        }
      </Link>
      <scoped.styles />
      {isMobile && type === 0 && <Divider className={styles.divider} />}
    </div>
  );
}


Card8.propTypes = {
  content: relationshipsPropTypes({
    horoscope: horoscopePropTypes(),
  }),
  isMobile: PropTypes.bool,
  type: PropTypes.oneOf([0, 1]),
  theme: PropTypes.object,
};

Card8.defaultProps = {
  type: 0,
};

const Card = withCard(Card8);

export const Card8Type1 = withBindProps({ type: 1 })(Card);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
