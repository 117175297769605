import { useContext } from 'react';
import PropTypes from 'prop-types';

import withBindProps from 'core/hocs/withBindProps';
import Drum from 'core/components/Drum';
import { SimpleLoader } from 'core/components/Loader';
import withBreakpoint from 'core/hocs/withBreakpoint';

import CardBirthday from 'site/cards/CardBirthday';
import SectionTitle from 'site/components/SectionTitle';
import { StarsBirthdayContext } from 'site/components/StarsBirthdayContext';

import styles from './index.styl';


const DrumTitle = withBindProps({ align: 'left', level: 4, className: styles.sectionTitle })(SectionTitle);
const DrumTitleMobile = withBindProps({ align: 'center', level: 1, className: styles.sectionTitle })(SectionTitle);

function StarsBirthday(props) {
  const {
    isDesktop,
  } = props;

  const {
    starsBirthday: content,
    isLoading,
  } = useContext(StarsBirthdayContext);

  if (isLoading) return <SimpleLoader />;
  if (!content?.length) return null;

  return (
    <div className={styles.container}>
      <Drum
        dataQa='stars-birthday'
        title='День рождения'
        headerComponent={isDesktop ? DrumTitle : DrumTitleMobile}
        card={CardBirthday}
        content={content}
        itemWidth='100%'
        {...(content?.length === 1) && { itemWidthMobile: '100%' }}
        arrowsShift={10}
        noCounter
        noFade
      />
    </div>
  );
}

StarsBirthday.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(StarsBirthday);
