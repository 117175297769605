import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';
import Feed from 'core/components/Feed';

import SectionTitle from 'site/components/SectionTitle';
import { NegativeMobileSideIndent } from 'site/components/Indents';
import Card8 from 'site/cards/Card8';

import styles from './index.styl';

const Horoscope = ({ content, isDesktop }) => (
  <NegativeMobileSideIndent>
    <SectionTitle align='center' className={styles.sectionTitle}>
      Гороскоп{isDesktop ? ' на сегодня' : ''}
    </SectionTitle>
    <div {...!isDesktop && { className: styles.wrapper }} >
      <YandexMetrikaGoalSender click={'horoscopes_head_click'} show={'horoscopes_head_shows'}>
        <Feed
          content={content}
          interitemSpacing={isDesktop ? 30 : 0}
          card={Card8}
          columns={6}
          grid
          dataQa='horoscope'
        />
      </YandexMetrikaGoalSender>
    </div>
  </NegativeMobileSideIndent>
);

Horoscope.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Horoscope);
