import PropTypes from 'prop-types';

import React from 'react';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';
import withBindProps from 'core/hocs/withBindProps';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import mapTypes from './types';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Card9(props) {
  const {
    content,
    imageMaxWidth,
    imageMaxHeight,
    breakpoint,
    theme,
    type,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    large,
  } = mapTypes[type] || {};

  return (
    <div className={cx(
      styles.card,
      styles[`card--layout-${breakpoint}`],
      large && styles['card--large'],
    )} data-qa='card9'
    >
      <style jsx>{`
        .headline
          font-family ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .image-fade
          background ${theme.colors.content}
          transition opacity ${theme.animations.hover}
      `}</style>

      <Link
        to={link}
        type='secondary'
        className={styles['card-link']}
        data-qa='card9-link'
      >
        <div className={styles['card-image']}>
          <Image
            src={cover}
            previewSrc={previewCover}
            position='50% 0'
            maxWidth={imageMaxWidth}
            maxHeight={imageMaxHeight}
          />
        </div>
        <div className={cx(
          styles['card-image_fade'],
          'image-fade',
        )}
        />
        <div className={styles['card-content']}>
          <div
            className={cx(
              styles['card-content_block'],
              styles['card-headline'],
              'headline',
            )}
            data-qa='card9-headline'
          >
            <MarkdownWrapper inline>
              {listHeadline || headline}
            </MarkdownWrapper>
          </div>
        </div>
      </Link>
    </div>
  );
}

Card9.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  imageMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageMaxHeight: PropTypes.string,
  type: PropTypes.oneOf([0, 1]),
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

Card9.defaultProps = {
  type: 0,
};

const Card = withCard(Card9);

export const Card9Type1 = withBindProps({ type: 1 })(Card);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
